<template>
  <v-container class="home" fluid @keypress.esc="escape">

    <smart-widget-grid :layout="layout"
                       :colNum="cols"
                       :rowHeight="rows.height"
                       :verticalCompact="false"
                       @layout-updated="layoutUpdated"
                       :key="cols+rows.height"
                       :class="customizing ? 'marginBottom' : ''"
    >

      <widget v-for="(widget, i) in widgets" :key="i+'widget'"
              :slot="i"
              :editable="customizing"
              :componentName="widget.name"
              :pathToWidget="widget.path"
      ></widget>

    </smart-widget-grid>

    <v-btn
        @click="customizing = !customizing"
        color="primary"
        class="mr-2 mb-2"
        dark
        fixed bottom right
        fab
    >
      <v-icon v-if="customizing">
        mdi-close
      </v-icon>
      <v-icon v-else>
        mdi-palette-advanced
      </v-icon>
    </v-btn>
    <div v-if="customizing" class="contextual-customize d-flex flex-row justify-stretch">
      <v-card class="d-flex flex-column align-center justify-center elevation-4 pb-4 pt-3 px-4 rounded ml-4">
        <div class="mb-1">{{ $t("home.custom.background") }}</div>
        <div class="contextual-customize-actions">
          <v-btn
              fab
              dark
              x-small
              color="info"
              @click="showChangeBackground = !showChangeBackground"
          >
            <v-icon>mdi-image-multiple</v-icon>
          </v-btn>
        </div>
      </v-card>
      <v-card class="d-flex flex-column align-center justify-center elevation-4 pb-4 pt-3 px-4 rounded ml-4">
        <div class="mb-1">{{ $t("home.custom.columns") }} ({{ cols }})</div>
        <div class="contextual-customize-actions">
          <v-btn
              fab
              dark
              x-small
              color="red"
              class="mr-2"
              @click="changeColumn(-1)"
          >
            <v-icon>mdi-minus</v-icon>
          </v-btn>
          <v-btn
              fab
              dark
              x-small
              color="green"
              @click="changeColumn(1)"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-card>
      <v-card class="d-flex flex-column align-center justify-center elevation-4 pb-4 pt-3 px-4 rounded ml-4">
        <div class="mb-1">{{ $t("home.custom.rows") }} ({{ rows.count }})</div>
        <div class="contextual-customize-actions">
          <v-btn
              fab
              dark
              x-small
              color="red"
              class="mr-2"
              @click="changeRow(-1)"
          >
            <v-icon>mdi-minus</v-icon>
          </v-btn>
          <v-btn
              fab
              dark
              x-small
              color="green"
              @click="changeRow(1)"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-card>
    </div>

    <change-background v-model="showChangeBackground"></change-background>

    <CookieModal v-if="showPopup">

    </CookieModal>

  </v-container>
</template>
<script>

import PlaneteOnlineService from "@/Services/PlaneteOnlineService";
import Vuex from "vuex";
import CookieModal from "../Home/CookieModal";


export default {
  name: 'Home',
  components: {
    Widget: () => import("@/Components/Views/Home/Widgets/Widget"),
    ChangeBackground: () => import("@/Components/Views/Home/ChangeBackground"),
    CookieModal,
  },
  computed: {
    ...Vuex.mapState(["widgetBoard", "preferences","informations"]),
    widgets() {
      return this.widgetBoard.widgets
    },
    cols: {
      get() {
        return this.preferences?.widgetBoard?.cols || 6
      },
      set(val) {
        this.$store.commit("setWidgetBoardCols", {cols: val})

        let store = this.$_.pick(this.$store.state, ["preferences"])
        PlaneteOnlineService.postInterface(store)
      }
    },
    rows: {
      get() {
        let rowHeights = {3: 189, 4: 139, 5: 109, 6: 89, 7: 75, 8: 64}
        return {
          count: (this.preferences?.widgetBoard?.rows || 6),
          height: (rowHeights[this.preferences?.widgetBoard?.rows] || 139)
        }
      },
      set(val) {
        this.$store.commit("setWidgetBoardRows", {rows: val})

        let store = this.$_.pick(this.$store.state, ["preferences"])
        PlaneteOnlineService.postInterface(store)
      }
    }
  },
  data() {
    return {
      customizing: false,
      layout: [],
      showColumnMenu: false,
      showRowMenu: false,
      showChangeBackground: false,
      showPopup: false
    }
  },
  methods: {
    accept() {
            this.showPopup = false;
            localStorage.setItem('accepted', true);
    },
    changeColumn(quantity) {
      let tmp = this.cols
      tmp += +quantity
      if (tmp > 8) tmp = 8
      if (tmp < 3) tmp = 3
      this.cols = tmp
    },
    changeRow(quantity) {
      let tmp = this.rows.count
      tmp += +quantity
      if (tmp > 8) tmp = 8
      if (tmp < 3) tmp = 3
      this.rows = tmp
    },
    layoutCompute() {
      this.layout = this.widgets.map((a, i) => {
        return {
          x: a.position.x,
          y: a.position.y,
          w: a.width,
          h: a.height,
          i: i
        }
      })
    },
    layoutUpdated: async function (newLayout) {
      if (this.customizing) {
        for (let lay of newLayout) {
          this.$store.commit("newLayoutWidget", {newLayout: lay, widgetName: this.widgets[lay.i].name})
        }
        let store = this.$_.pick(this.$store.state, ["widgetBoard"])
        let response = await PlaneteOnlineService.postInterface(store)
      }
    }
  },
  async mounted() {
    this.layoutCompute()
    const hasAccepted = localStorage.getItem('accepted');
        if (!hasAccepted && !this.informations.superAdministrator) {
            this.showPopup = true;
        }
    
  },
  watch: {
    widgets: {
      deep: true,
      handler() {
        this.layoutCompute()
      }
    }
  }
}
</script>

<style>
.widget-body__content {

  padding: 0 !important;
  height: inherit;
}

.smartwidget {
  background: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  border: 0 !important;
}

.smartwidget > .widget-header {
  line-height: 0 !important;
  height: 0 !important;
  border: 0 !important;
}

.smartwidget > .widget-body {
  padding-bottom: 2px !important;
  height: 100% !important;
  overflow: visible !important;
  display: -webkit-box !important;
}

.smartwidget > .widget-body-simple {
  padding-bottom: 2px !important;
  height: 100% !important;
  overflow: visible !important;
  display: -webkit-box !important;
}

.contextual-customize {
  position: fixed;
  bottom: 16px;
  right: 104px;
}


.marginBottom {
  margin-bottom: 100px;
}
</style>