<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="50%"
      persistent
    >
    <v-card>
        <v-card-title class="text-h5">
          {{ $t('notifications.cookies_title') }}
        </v-card-title>
        <v-card-text>
          {{ $t('notifications.cookies_message') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="btn-cookie"
            variant="text"
            @click="accept"
          >
            {{ $t('notifications.cookies_accept') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "CookieModal",
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    accept() {
            this.dialog = false;
            localStorage.setItem('accepted', true);
        },
  },
  mounted() {
    if (!localStorage.getItem('accepted')) {
      this.dialog = true;
    }
  },
};
</script>

<style scoped>
.btn-cookie {
  background-color: rgb(24, 24, 177) !important;
  color: #fff;
}
</style>